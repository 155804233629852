class DisplayCalculette {
    constructor(el) {
        this.el = $(el);
        this.url = this.el.data('href');
    }

    showCalculette(Modal){
        $.ajax({
            type: 'POST',
            url: this.url,
            async: false,
            success: function (res) {
                
                if(res.sent == 'OK' ){
                    //console.log(res);
                    if(!$('#calculetteModal').length){
                        let htmlModal = '<div id="calculetteModal" class="modal fade" tabindex="-1">'
                        htmlModal += '<div class="modal-dialog modal-dialog-centered modal-lg">'
                        htmlModal += '    <div class="modal-content">'
                        htmlModal += '    <div class="modal-header">'
                        htmlModal += '        <h5 class="modal-title">Calculette</h5>'
                        htmlModal += '        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>'
                        htmlModal += '    </div>'
                        htmlModal += '    <div class="modal-body">'
                        htmlModal +=           res.text
                        htmlModal += '    </div>'
                        htmlModal += '    <div class="modal-footer">'
                        htmlModal += '        <button type="button" class="btn btn-primary" data-bs-dismiss="modal">OK</button>'
                        htmlModal += '    </div>'
                        htmlModal += '    </div>'
                        htmlModal += '</div>'
                        htmlModal += '</div>'

                        $('footer').append(htmlModal);
                    }
            
                    var myModal = new Modal($('#calculetteModal'), {
                        keyboard: false
                    })

                    myModal.show();
                    
                }
            },
            error: function (err) {
                alert("An error occurred while sorting. Please refresh the page and try again.")
            }
        });
    }

    calcMontant(){
        if( isNaN($('#montantMensualite').val()) || $('#montantMensualite').val() == ''){
            $('#montantMensualite').val(750);
        }

        if( isNaN($('#montantTaux').val()) || $('#montantTaux').val() == ''){
            $('#montantTaux').val(2);
        }

        if( isNaN($('#montantDuree').val()) || $('#montantDuree').val() == ''){
            $('#montantDuree').val(20);
        }

        let mensualite = $('#montantMensualite').val() ? parseFloat($('#montantMensualite').val().replace(',','.')) : 750;
        let taux = $('#montantTaux').val() ? parseFloat($('#montantTaux').val()) / 100 : 0.02;
        let duree = $('#montantDuree').val() ? parseInt($('#montantDuree').val()) : 20;
        
        let coef = (taux / 12) / (1 - Math.pow((1 + ( taux / 12 ) ), (-12 * duree)));
        let montant = String(Math.round(mensualite / coef));
        $('#montantResult').html(montant.replace('.',',') + ' €');
    }

    calcMensualite(){

        if( isNaN($('#mensualiteMontant').val()) || $('#mensualiteMontant').val() == ''){
            $('#mensualiteMontant').val(200000);
        }

        if( isNaN($('#mensualiteTaux').val()) || $('#mensualiteTaux').val() == ''){
            $('#mensualiteTaux').val(2);
        }

        if( isNaN($('#mensualiteDuree').val()) || $('#mensualiteDuree').val() == ''){
            $('#mensualiteDuree').val(20);
        }
        
        let montant = parseFloat($('#mensualiteMontant').val().replace(',','.').replace(' ',''));
        let taux = parseFloat($('#mensualiteTaux').val()) / 100;
        let duree = parseInt($('#mensualiteDuree').val());
        let coef = (taux / 12) / (1 - Math.pow((1 + ( taux / 12 ) ), (-12 * duree)));
        let mensualite = String(Math.round(montant * coef * 100) / 100);
        let mensualiteResult = mensualite.replace('.',',');
        console.log(isNaN(montant));
        
        $('#mensualiteResult').html(mensualiteResult + ' €');
        
    }
}

export default DisplayCalculette;