$(document).ready(function() { 
    
    let showcaseHeight = 0;
    let lastScroll = 0;
    let nowScroll = 0;
    let del = 5;
    let navbarFixed = false;
    
    // $.fn.stickyMenu = function(){
    $(window).scroll(function() {
        nowScroll = $(window).scrollTop();
        
        if(( $('#anchor').offset().top - 100 ) <= $(window).scrollTop() ){
            $('#mainMenu').addClass('fixed-top');
            showcaseHeight = parseInt($('#showcase').height()) - 250;

            if(Math.abs(lastScroll - ( nowScroll + 5 ) ) >= del && navbarFixed == true){
                if(nowScroll > lastScroll ){
                    $('#mainMenu').addClass('move-up').removeClass('move-down');
                    $('#navTransaction').addClass('move-up').removeClass('move-down');
                }
                else{
                    $('#mainMenu').addClass('move-down').removeClass('move-up');
                    $('#navTransaction').addClass('move-down').removeClass('move-up');
                }
                
                lastScroll = nowScroll;
            }

            navbarFixed = true;
        }
        else if($(window).scrollTop() <= showcaseHeight){
            $('#mainMenu').addClass('no-move').removeClass('move-down');
            $('#navTransaction').addClass('no-move').removeClass('move-down');
        }
        else{
            $('#mainMenu').removeClass('fixed-top').removeClass('no-move');
            $('#navTransaction').removeClass('fixed-top').removeClass('no-move');
        }

        
  
    });
    // }
});