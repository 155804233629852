class SubmitEstimationForm {
    constructor(el) {
        this.el = $(el);
        this.message = {
            1: 'Choisissez un type de bien',
            2: 'Précisez les caractéristiques du bien',
            3: 'Information complémentaires',
            4: 'L\'adresse du bien',
            5: 'Vos coordonnées',
            6: 'Votre e-mail'
        }
        let url = this.el.attr('action');

        let h = parseInt($('.tab-1 .row', this.el).height() + 75 );
        
        $('.tabs-container', this.el).css('height', ( h < 300 ? '300px' : h + 'px' ));
        
    }

    submitForm(){
        this.el.submit();
    }

    slideTab(step, slide, valideFields){
        let valLastName = this.valideTab(valideFields);
        
        if(!valLastName && step != 7 ){
            let h = parseInt($('.tab-' + step).height() + 5 );
            let delaySetTimeOut1 = 0;
            let delaySetTimeOut2 = 0;

            slide == 1 ? delaySetTimeOut1 = 300 : delaySetTimeOut1 = 500;
            slide == 1 ? delaySetTimeOut2 = 500 : delaySetTimeOut2 = 300;
            $('#estimation-text p').fadeOut(500);
            setTimeout(() => {
                $('#estimation-text p').text(this.message[step]).fadeIn();
            }, 500);

            setTimeout(() => {
                $('.tabs-container', this.el).css('height', ( h < 300 ? '300px' : h + 'px' ));
            }, delaySetTimeOut1);

            setTimeout(() => {
                $('.tab-' + ( step - slide )).removeClass('slide-middle');
                if(slide == 1){
                    $('.tab-' + ( step - slide )).removeClass('slide-right');
                    $('.tab-' + ( step - slide )).addClass('slide-left');
                }
                else{
                    $('.tab-' + ( step - slide )).removeClass('slide-left');
                    $('.tab-' + ( step - slide )).addClass('slide-right');
                }

                $('.tab-' + step).removeClass('slide-left');
                $('.tab-' + step).removeClass('slide-right');
                $('.tab-' + step).addClass('slide-middle');

                $('.step-item-' + ( step - slide ) ).removeClass('active');
                $('.step-item-' + step ).addClass('active');
            }, delaySetTimeOut2);
        }
        else if(!valLastName && step == 7){
            this.submitForm();
        }
        
    }

    valideTab(fields){
        let arrError = [];
        let i = 0;

        for (let field in fields) {
            let error = false;
            if(fields[field] == 'input'){
                error = this.validInput(field);
                arrError[i] = error;
            }
            else if(fields[field] == 'inputInt'){
                error = this.validInputInt(field);
                arrError[i] = error;
            }
            else if(fields[field] == 'textarea'){
                error = this.validTextarea(field);
                arrError[i] = error;
            }
            else if(fields[field] == 'typebien'){
                error = this.validTypeBien(field) 
                arrError[i] = error;
            }
            else if(fields[field] == 'email'){
                error = this.validEmail(field)
                arrError[i] = error;
            }
            i ++;
        }

        for (let err in arrError) {
            if(arrError[err]){
                return true;
            }
        }

        return false;
    }

    validInput(input){
        let error = false;
        let el = $(input);
        let elError = $(input + '_error');
        let fname = el.val();
        
        let textError = '';
        
        if (fname == '') {
            textError += 'Vous devez remplir ce champ.<br>';
            el.addClass("box_error");
            error = true;
        } else {
            elError.text('');
            error = false;
        }
        if ((fname.length <= 2) || (fname.length > 20)) {
            textError += 'Le nombre de caratères doit être compris entre 2 et 20 caratères.<br>';
            el.addClass("box_error");
            error = true;
        }
        if (!isNaN(fname)) {
            textError += 'Seuls les caratères sont autorisés.<br>';
            el.addClass("box_error");
            error = true;
        }
        
        if(textError != ''){
            elError.html(textError);
        } 
        else{
            el.removeClass("box_error");
        }
        
        return error;
    }

    validInputInt(input){
        let error = false;
        let el = $(input);
        let elError = $(input + '_error');
        let fname = el.val();
        
        let textError = '';
        
        if (fname == '') {
            textError += 'Vous devez remplir ce champ.<br>';
            el.addClass("box_error");
            error = true;
        } else {
            elError.text('');
            error = false;
        }
        if (isNaN(fname)) {
            textError += 'Seuls les chiffres sont autorisés (ex. : 45 ou 35.80).<br>';
            el.addClass("box_error");
            error = true;
        }
        
        if(textError != ''){
            elError.html(textError);
        } 
        else{
            el.removeClass("box_error");
        }
        
        return error;
    }

    validTextarea(choice){
        let error = false;
        let el = $(choice);
        let elError = $(choice + '_error');
        let fname = el.val();
        let textError = '';
        
        if (fname == '') {
            textError += 'Vous devez remplir ce champ.';
            el.addClass("box_error");
            error = true;
        } else {
            elError.text('');
            error = false;
        }

        if(textError != ''){
            elError.html(textError);
        } 
        else{
            el.removeClass("box_error");
        }

        return error;
    }

    validTypeBien(choice){
        let error = false;
        let el = $(choice);
        let elError = $(choice + '_error');
        let fname = el.val();
        let textError = '';
        
        if (fname == 'none') {
            textError += 'Sélectionnez un type de bien';
            el.addClass("box_error");
            error = true;
        } else {
            elError.text('');
            error = false;
        }

        if(textError != ''){
            elError.html(textError);
        } 
        else{
            el.removeClass("box_error");
        }

        return error;
    }

    validEmail(email) {
        let error = false;
        let el = $(email);
        let elError = $(email + '_error');
        let fname = el.val();
        let textError = '';
        
        let regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if(!regex.test(fname)) {
            textError = 'L\'e-mail n\'est pas valide';
            el.addClass("box_error");
            error = true;
        }else{
            textError = '';
            el.removeClass("box_error");
            error = false;
        }

        elError.html(textError);

        return error;
    }

}

export default SubmitEstimationForm;