class DisplayHonoraires {
    constructor(el) {
        this.el = $(el);
        this.url = this.el.data('href');
    }

    showHonoraires(Modal){
        $.ajax({
            type: 'POST',
            url: this.url,
            async: false,
            success: function (res) {
                
                if(res.sent == 'OK' ){
                    //console.log(res);
                    if(!$('#honorairesModal').length){
                        let htmlModal = '<div id="honorairesModal" class="modal fade" tabindex="-1">'
                        htmlModal += '<div class="modal-dialog modal-dialog-centered modal-xl">'
                        htmlModal += '    <div class="modal-content">'
                        htmlModal += '    <div class="modal-header">'
                        htmlModal += '        <h5 class="modal-title">' + res.content.title + '</h5>'
                        htmlModal += '        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>'
                        htmlModal += '    </div>'
                        htmlModal += '    <div class="modal-body">'
                        htmlModal +=           res.content.text
                        htmlModal += '    </div>'
                        htmlModal += '    <div class="modal-footer">'
                        htmlModal += '        <button type="button" class="btn btn-primary" data-bs-dismiss="modal">OK</button>'
                        htmlModal += '    </div>'
                        htmlModal += '    </div>'
                        htmlModal += '</div>'
                        htmlModal += '</div>'

                        $('footer').append(htmlModal);
                    }
                    
                    var myModal = new Modal($('#honorairesModal'), {
                        keyboard: false
                    })

                    myModal.show();
                    
                }
            },
            error: function (err) {
                alert("An error occurred while sorting. Please refresh the page and try again.")
            }
        });
    }
}

export default DisplayHonoraires;